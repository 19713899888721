/**
@author: NiceNeko for Neko-san
*/
//

var rulesComponent = Vue.extend({
	template: MultiString(function() {/**
		<div class="mainViewCentered">
			<h1><i class="material-icons md-font-large">gavel</i>Règles</h1>

            <ul class="demo-list-item mdl-list">
                <li class="mdl-list__item">
                    <span class="mdl-list__item-primary-content">
                        <i class="material-icons mdl-list__item-icon">keyboard_arrow_right</i>
                        Respectez les décisions prises par les Modérateurs et les Admins si elles ont été appliquées c'était pour une bonne raison.
                    </span>
                </li>
                <li class="mdl-list__item">
                    <span class="mdl-list__item-primary-content">
                        <i class="material-icons mdl-list__item-icon">keyboard_arrow_right</i>
                        Modérez votre langage, ne soyez pas offensant, alternativement vous serez succeptible d'être averti ou banni.
                    </span>
                </li>
                <li class="mdl-list__item">
                    <span class="mdl-list__item-primary-content">
                        <i class="material-icons mdl-list__item-icon">keyboard_arrow_right</i>
                        Le contenu à caractère pédophile, pornographique, politique, propagande, raciste ou religieux entraîne un ban temporaire voire définitif.
                    </span>
                </li>
                <li class="mdl-list__item">
                    <span class="mdl-list__item-primary-content">
                        <i class="material-icons mdl-list__item-icon">keyboard_arrow_right</i>
                        Les informations personnelles ne concernent vous et que vous, merci de ne pas les divulguer.
                    </span>
                </li>
                <li class="mdl-list__item">
                    <span class="mdl-list__item-primary-content">
                        <i class="material-icons mdl-list__item-icon">keyboard_arrow_right</i>
                        Le spam, la provocation, le troll, la pub, le spoil et le flood ne sont pas appréciés et peuvent entraîner un bannissement définitif.
                    </span>
                </li>
                <li class="mdl-list__item">
                    <span class="mdl-list__item-primary-content">
                        <i class="material-icons mdl-list__item-icon">keyboard_arrow_right</i>
                        Les pseudos (surnoms / noms d'utilisateurs) et avatars jugés incorrects par les Modérateurs ou Admins seront susceptibles d'entrainer une sanction.
                    </span>
                </li>
                <li class="mdl-list__item">
                    <span class="mdl-list__item-primary-content">
                        <i class="material-icons mdl-list__item-icon">keyboard_arrow_right</i>
                        Prendre part à la discussion, activement et/ou passivement, signifie que ce règlement a été lu et approuvé dans sa totalité.
                    </span>
                </li>
            </ul>
		</div>
	**/}),
	data: function(){
		return {
			show: true
		}
	}
});
