/* Constantes */
var CONNECTION_HUB = 'https://neko-san.fr/hub.html';
var APP_URL = 'https://feedback.neko-san.fr';
var MOTHER_APP = 'https://neko-san.fr/';
var AUTH_LINK = MOTHER_APP + 'touch-and-go/' + encodeURIComponent(APP_URL);
var AVATAR_BASE = 'https://resources.neko-san.fr/img/avatar/';
var MAIN_ICON = 'http://resources.neko-san.fr/img/ui/logo.png';

var TIME_NOTIONS = {	// In seconds!
	minute: 60,
	hour: 60*60,
	day: 60*60*24,
	month: 2629800,
	year: 31557600
};

var GAUGE_DELAY_ANIMATION = 2000;	// Vote counter animation. In millisecond!
var REFRESH_DELAY = 1000; // Used for refreshing widgets like time label. In millisecond!
var CASCADE_DELAY = 100; // Delay between each feedback to be triggered to render a cascade effect. In millisecond!
var CHECK_DELAY = 10000; // Delay interval to check if new feedback has arrived. If so, all the component with the related hook attached will update themself. In millisecond!

var LIMITATION_DESC_CHAR = 150; // Maximum characters allowed before truncating message. Used in feedback lists and browser notifications!
var MINIMAL_BODY_SIZE_DESC = 20; // Minimal character number to be accepted when posting a feedback.
var MAX_COMMENT_SIZE = 4096;	// It speaks itself.

var HTTP_CODE = {
	FOUND_OK: 200,
	CREATED: 201,

	BAD_REQUEST: 400,
	UNAUTHORIZED: 401,
	FORBIDDEN: 403,
	NOT_FOUND: 404,
	METHOD_NOT_ALLOWED: 405,
	CONFLICT: 409,
	IM_A_TEAPOT: 418,
	UNPROCESSABLE_ENTITY: 422,
	TOO_MANY_REQUESTS: 429,

	INTERNAL_SERVER_ERROR: 500,
	NOT_IMPLEMENTED: 501,
	SERVICE_UNAVAILABLE: 503,
};

var MAINTENANCE = false;	// TODO maintenance mode
