/**
@author: NiceNeko for Neko-san
*/
//

var feedbackBoardComponent = Vue.component('feedbackBoard', {
	template: MultiString(function() {/**
		<div class="mdl-cell mdl-cell--8-col-desktop mdl-cell--8-col-tablet mdl-cell--4-col-phone">
			<!-- FILTER GOES RIGHT HERE -->
			<div class="mdl-textfield mdl-js-textfield md-large-field mdl-js-dyn">
			    <input class="mdl-textfield__input" id="searchVal" v-model="searchValue" v-on:input="fetchFeedbacks">
			    <label class="mdl-textfield__label iflex" for="searchVal">
					<i class="material-icons">search</i>
					<span>Rechercher un Feedback</span>
				</label>
			</div>
			<div class="categoriesList">
				<label v-for="(cat, index) in categories" :key="cat.id" class="mdl-switch mdl-js-switch mdl-js-ripple-effect mdl-js-dyn" :for="'switch-' + cat.name">
					<input v-model="currentCategories" type="checkbox" :id="'switch-' + cat.name" :value="cat.id" class="mdl-switch__input">
					<span class="mdl-switch__label">{{ cat.name }}</span>
				</label>
			</div>
			<feedbackList
				v-bind:feedbacks="feedbacks"
				v-bind:useAnimation="useAnimation"
			>
			</feedbackList>
		</div>
	**/}),
	created(){
		this.fetchFeedbacks();
		this.fetchCategories();
	},
    activated(){
		this.fetchFeedbacks();
		this.fetchCategories();
    },
	methods: {
		fetchFeedbacks(){
			var _self = this;
			if(this.lastTimeOut != '')
				clearTimeout(this.lastTimeOut);
			this.lastTimeOut = setTimeout(function(e){   // Temporize user input before lauching search
				$.getJSON(API_ROUTES.searchFeedback, {  // Get feedbacks
					terms: _self.searchValue,
					category: _self.currentCategories
				})
				.done(function(data_feedbacks){
                    if(data_feedbacks.length > 0){
                        var list_uuid = $.unique(data_feedbacks.map(function(fb) { return fb.user_uuid; })).join(',');

            			$.ajax({     // Get users
            			    url: API_ROUTES.user,
            			    type: 'POST',
            				data: {
            			 		user_uuid_list: list_uuid,
                                token: _self.token,
                                app_uuid: sessionStorage.APP_UUID
            				},
            				success: function(data_users){
                                var _users = [];
                                for(index in data_users.user){
                                    _users[data_users.user[index].user_uuid] = data_users.user[index];
                                }
                                for(index in data_feedbacks){
                                    data_feedbacks[index].user = _users[data_feedbacks[index].user_uuid];
                                }
            					_self.feedbacks = data_feedbacks;  // Merge users data and feedbacks
            				},
            				error: function(err){
            					alertModal('Oups', 'Une erreur est survenue pendant la récupération des données. Si le problème persiste, contactez un administrateur avec le code suivant: ' + err.status, { name: 'home' });
            					console.log('Server error:');
            					console.log(err);
            				}
            			});
                    }
				}).fail(function(err){
					if(err.status === HTTP_CODE.TOO_MANY_REQUESTS){
						alertModal('Attention', 'Merci de ne pas spammer l\'interface! Pour des raisons de sécurité, vous avez été bloqué quelques secondes.');
					}
					else if(err.status === HTTP_CODE.NOT_FOUND){
						_self.feedbacks = [];
					}
					else{
						alertModal('Erreur', 'Erreur serveur! Code ' + err.status);
						console.log('Server error:');
						console.log(err);
					}
				});
			}, 250);
		},
		fetchCategories(){
			var _self = this;
			$.getJSON(API_ROUTES.category)
			.done(function(data){
				_self.categories = data;
				_self.$nextTick(function(){
					if(typeof componentHandler !== 'undefined'){ // Not needed at startup (+ cause error as the lib is not loaded yet at this point)
						componentHandler.upgradeElements(document.querySelectorAll('.mdl-js-dyn'));
					}
				});
				//_self.getNotes();
			}).fail(function(err){
				alert('Server error!');
				console.log('Server error:');
				console.log(err);
			});
		}/*,
		getNotes(){
			var len = this.feedbacks.length;
			var _self = this;
			for(var i=0; i < len; i++){
				$.ajax({
					url: 'api/feedback/'+ _self.feedbacks[i].id +'/votes',
					dataType: 'json',
					context: { position: i }
				}).done(function(data){
					var sum = 0;
					for(var index in data)
						sum += data[index].type;
					_self.feedbacks[this.position].note = sum;
					console.log('Sum: ' + sum);
				}).fail(function(err){
					alert('Server error!');
					console.log('Server error:');
					console.log(err);
				});
			}
		}*/
	},
	data: function(){
		return {
			searchValue: '',
			currentCategories: [],
			feedbacks: [],
			fbks: [],
			categories: [{
				id: 0,
				name: 'category',
				desc: ''
			}],
			useAnimation: true
		}
	},
	watch:{
		currentCategories(){
			this.fetchFeedbacks();
		},
		refreshHook(){
			this.fetchFeedbacks();
		},
		'$route': function(){
			if(this.$route.name == 'home')
				this.fetchFeedbacks();
		}
	}
});
