/**
@author: NiceNeko for Neko-san
*/

var feedbackFormComponent = Vue.extend({
	template: MultiString(function() {/**
				<div id="feedbackCreate" class="mainViewCentered">
					<form>
						<div id="titleFeedback" class="mdl-textfield mdl-js-textfield md-large-field mdl-js-dyn">
							<input class="mdl-textfield__input" v-model="title" pattern="^.{0,50}$">
						    <label class="mdl-textfield__label" for="titleFeedback">Titre du feeback</label>
							<span class="mdl-textfield__error">Le titre est trop long!</span>
						</div>
                        {{ bodyMessage }}
						<div class="bodyMessageEditorContainer">
							<div v-medium class="bodyMessageEditor" placeholder="Tapez votre description ici"></div>
						</div>
						<textarea id="interfaceEditor" name="bodyMessage" v-model="bodyMessage"></textarea>
						<div class="categoriesList">
							<template v-for="cat in categories">
								<label class="mdl-radio mdl-js-radio mdl-js-ripple-effect mdl-js-dyn" v-bind:for="'radio-' + cat.id">
									<input type="radio" :id="'radio-' + cat.id" name="category" class="mdl-radio__button" v-model="selectedCategory" :value="cat.id">
									<span class="mdl-radio__label">{{ cat.name }}</span>
								</label>
							</template>
						</div>
						<!-- Accent-colored flat button -->
						<button id="validSaisie" v-on:click="storeFeedback" class="mdl-button mdl-js-button mdl-button--accent mdl-js-ripple-effect">
							Enregistrer
						</button>
						<div id="formLoader" class="mdl-progress mdl-js-progress mdl-progress__indeterminate mdl-js-dyn"></div>
					</form>
				</div>
	**/}),
	props: {
		user: {
			type: Object,
			required: true
		},
		token: {
			type: String,
			required: true
		}
	},
	created(){
		this.fetchCategories();
	},
	activated(){
		if(this.user.id == 0){
			alertModal('Connectez-vous', 'Vous devez être connecté pour accéder à cette fonctionnalité!<br />Pas encore de compte? Rendez-vous sur <a href="'+ MOTHER_APP +'">Neko-San.fr</a> pour vous inscrire!', { name: 'home' });
		}
        if(typeof this.$route.params.feedbackid != 'undefined'){
            this.fetchFeedback();
        }
	},
    mounted(){
        if(typeof this.$route.params.feedbackid != 'undefined'){
            this.fetchFeedback();
        }
        //this.fetchsection();
		if(typeof componentHandler !== 'undefined'){ // Not needed at startup (+ cause error as the lib is not loaded yet at this point)
			componentHandler.upgradeElements(document.querySelectorAll('.mdl-js-dyn'));
		}
    },
    computed: {
        finalBody(){
            return this.bodyMessage.replace(/\[\[(.*?)\]\]/g, '<img src="$1"></img>');   // TO ENHANCE! + filter todo on server side to avoid XSS
        }
    },
	methods: {
		fetchCategories(){
			var _self = this;
			$.getJSON(API_ROUTES.category, { })
			.done(function(data){
				_self.categories = data;
				if(typeof componentHandler !== 'undefined'){ // Not needed at startup (+ cause error as the lib is not loaded yet at this point)
					_self.$nextTick(function(){	// Waiting for elements to be rendered in DOM before upgrading elements that requires MDL JS
						componentHandler.upgradeElements(document.querySelectorAll('.mdl-js-dyn'));
					});
				}
			}).fail(function(err){
				alert('Server error!');
				console.log('Server error:');
				console.log(err);
			});
		},
		storeFeedback(e){
            var _self = this;
			e.preventDefault();
			if(this.title.length === 0){
				alertModal('Petit oubli?', 'Merci de renseigner le titre du feedback (50 caractères max).');
				return false;
			}
			if(this.selectedCategory === ''){
				alertModal('Presque!', 'Votre feedback doit comporter une catégorie!');
				return false;
			}
			if(this.bodyMessage.length < MINIMAL_BODY_SIZE_DESC){
				alertModal('Un ptit effort', 'Votre description semble un peu courte...');
				return false;
			}

			$.ajax({
			    url: (API_ROUTES.feedback + ((_self.id != 0) ? '/' + _self.id : '')),
			    type: (_self.id != 0) ? 'PUT' : 'POST',
				data: {
					title: _self.title,
					bodyMessage: _self.bodyMessage,
					category_id: _self.selectedCategory,
                    token: _self.token
				},
			    success: function(result){
                    // _self.$router.push({name: 'feedbackPost', params: { feedbackid: (result.id_created || _self.id) } });
                    _self.$router.push({ name: 'home' });

					notification.MaterialSnackbar.showSnackbar({
					    message: (_self.id != 0) ? 'Feedback modifié!' : 'Feedback Ajouté! L\'équipe de modération va le valider!'
					});
                    _self.id = 0;
                    _self.title = '';
                    _self.bodyMessage = '';
			    },
				error: function(req){
					if(req.status == HTTP_CODE.UNPROCESSABLE_ENTITY){ // 422 Unproccessable Entity: (example) {"title":["validation.unique"]}
						alertModal('Titre feedback', 'Un feedback avec un titre similaire existe déjà!');
						return;
					}
					alertModal('Oups', 'Une erreur s\'est produite! Code: ' + req.status);
				}
			});
		},
        fetchFeedback(){
			if(typeof this.$route.params.feedbackid  == 'undefined')
				return;
			var _self = this;
			$.getJSON(API_ROUTES.feedback + '/' + _self.$route.params.feedbackid, {
                token: this.token
            })
			.done(function(data){
				_self.id = data.id;
				_self.title = data.title;
				_self.bodyMessage = data.bodyMessage;
                _self.selectedCategory = data.category.id;
                $('.bodyMessageEditorContainer > div').html(data.bodyMessage);
                $('.mdl-radio__button').parent().removeClass('is-checked');
                $('#radio-'+data.category.id).parent().addClass('is-checked');
			}).fail(function(err){
				alertModal('Oups', 'Une erreur est survenue pendant la récupération des données. Si le problème persiste, contactez un administrateur avec le code suivant: ' + err.status, { name: 'home' });
				console.log('Server error:');
				console.log(err);
			});
		}
	},
	data: function(){
		return {
            id: 0,
			title: '',
			bodyMessage: '',
            contentnEditable: '',
			selectedCategory: '',
			categories: []
		}
	},
    watch: {
        contentEditable(newVal, oldVal){
            this.bodyMessage = newVal;
        }
    }
});
