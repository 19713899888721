/**
@author: NiceNeko for Neko-san
*/

var feedbackListComponent = Vue.component('feedbackList', {
	template: MultiString(function() {/**
		<div class="feedbackList">
				<div class="feedbacksContainer">
					<!-- <transition name="fade" mode="out-in"> -->
						<ul class="mdl-list fb-block" v-bind:class="{ 'fb-block-bkg': blockDesign }">
							<transition-group
								v-if="useAnimation"
								name="staggered-fade"
								tag="div"
								v-bind:css="false"
								v-on:before-enter="beforeEnter"
								v-on:enter="enter"
								v-on:leave="leave"
							>
								<feedback
								v-for="(feedb, index) in feedbacks"
								:key="feedb.id"
								v-bind:feedb="feedb"
								v-bind:index="index"
								v-bind:useAnimation="useAnimation">
								</feedback>
							</transition-group>
							<feedback v-else
							v-for="(feedb, index) in feedbacks"
							:key="feedb.id"
							v-bind:feedb="feedb"
							v-bind:index="index">
							</feedback>
						</ul>
						<span v-if="fbEmpty" class="emptyMessage">Aucun Feedback!</span>
					<!-- </transition> -->
				</div>
			</div>
		</div>
	**/}),
	props: {
		feedbacks: {
			type: Array,
			required: true,
			default: []
		},
		useAnimation: {
			type: Boolean,
			required: false,
			default: false
		},
		blockDesign:{
			type: Boolean,
			required: false,
			default: false
		}
	},
	computed: {
		fbEmpty(){
			return !this.feedbacks.length;
		}
	},
	methods: {
		/* Transition Effects */
		beforeEnter: function (el) {
			el.style.display = "none";
			el.style.minHeight = "auto";
		},
		enter: function (el, done) {
			var delay = el.attributes.index.value * CASCADE_DELAY;
			setTimeout(function () {
				Velocity(
					el,
					"slideDown",
					{ complete: function(){ el.style.display = "flex"; $('.startDisplay', el).fadeIn('slow'); done(); } }
				);
			}, delay)
		},
		leave: function (el, done) {
			var delay = el.attributes.index.value * CASCADE_DELAY;
			setTimeout(function () {
				Velocity(
					el,
					"slideUp",
					{ complete: done }
				);
			}, delay)
		}
	},
	data: function(){
		return {}
	}
});
