console.log('%c Hey silly boy, what are you doing here? ;D', 'color: orange; font-weight: bold; font-size: 25px');

document.onkeypress = (e) => {
    var eo = window.event ? event : e;

    if (eo.keyCode == 28 && eo.ctrlKey) alertModal(decodeURI(atob('JUMyJUFGJTVDXyglRTMlODMlODQpXy8lQzIlQUY=')), atob('UHJheSBvdXIgR29kIE5pY2VOZWtvIHRvIGhhdmUgY3JlYXRlZCB0aGlzIHNpdGUh'));
}
// Multi-line hack
function MultiString(f) {
    return f.toString().split('\n').slice(1, -1).join('\n');
}

var decodeEntities = (function() {
    // this prevents any overhead from creating the object each time
    var element = document.createElement('div');

    function decodeHTMLEntities (str) {
        if(str && typeof str === 'string') {
            // strip script/html tags
            str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
            str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
            element.innerHTML = str;
            str = element.textContent;
            element.textContent = '';
        }
        return str;
    }

    return decodeHTMLEntities;
})();

var diffTopToolbar = -10;
if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {	// scope for mobile context initialisation
    diffTopToolbar = -55;
}

/* Notifications */
document.addEventListener('DOMContentLoaded', function () {
    if(typeof Notification == 'undefined') {
        alert('Desktop notifications not available in your browser. Try Chromium/Chrome or Firefox.');
        return;
    }
    if(Notification.permission !== "granted")
    Notification.requestPermission();
});

function notifyMe(title, bodyMessage, routeObj, icn) {
    icn = icn || MAIN_ICON;
    if (Notification.permission !== "granted")
    Notification.requestPermission();
    else {
        var opts = {
            icon: icn,
            body: decodeEntities(bodyMessage)
        }
        var _notification = new Notification(title, opts);

        _notification.onclick = function(e) {
            router.push(routeObj);
        };
    }
}

/* Text editor */
Vue.directive('medium', {
    bind(el){
        Vue.nextTick(() => {
            var editor = new MediumEditor(el, {
                placeholder: {
                    /* This example includes the default options for placeholder,
                    if nothing is passed this is what it used */
                    text: 'Tapez votre description ici!',
                    hideOnClick: true,
                    heightMin: 200
                },
                toolbar:{
                    //static: true,
                    //align: 'center',
                    //sticky: true,
                    updateOnEmptySelection: false,
                    diffTop: diffTopToolbar // For mobile view: important to avoid toolbar to be overlapped by native context menu (android, iOS, ...)
                }
            });

            editor.subscribe('editableInput', function(event, editable){
                //Medium Editor api for capture on change div contenteditable
                var interfaceElement = document.getElementById('interfaceEditor');
                var evt = document.createEvent("HTMLEvents");
                interfaceElement.value = editor.serialize()['element-0'].value;
                evt.initEvent("input", false, true);
                interfaceElement.dispatchEvent(evt);
            });
        });
    }
});

/* Dialog */
var dialog = document.querySelector('#dialog');
var showDialogButton = document.querySelector('#show-dialog');

if (!dialog.showModal) {
    dialogPolyfill.registerDialog(dialog);
}

function alertModal(title, bodyMess, redirect_to){
    window.dialogRedirect = redirect_to || '';
    document.getElementById('modal_title').innerText = title;
    document.getElementById('modal_bodyMsg').innerHTML = bodyMess;
    dialog.showModal();
};

/* Notification toaster */
var notification = document.querySelector('.mdl-js-snackbar');

// 1. Define route components.
// These can be imported from other files

// 2. Define some routes
// Each route should map to a component. The "component" can
// either be an actual component constructor created via
// Vue.extend(), or just a component options object.
// We'll talk about nested routes later.

var routes = [
    { path: '/', redirect: { name: 'home' }},
    { path: '/home', name: 'home', component: homeComponent },
    { path: '/feedbacks', name: 'feedbacks', component: homeComponent },
    { path: '/admin', name: 'admin', component: adminComponent },
    { path: '/feedback/new', name: 'feedbackCreate', component: feedbackFormComponent },
    { path: '/feedback/:feedbackid', name: 'feedbackPost', component: feedbackPostComponent },
    { path: '/feedback/edit/:feedbackid', name: 'feedbackEdit', component: feedbackFormComponent },
    { path: '/user', name: 'user', component: userComponent },
    { path: '/rules', name: 'rules', component: rulesComponent },
    { path: '/help', name: 'help', component: helpComponent }
    //{ path: '*', component: feedbackListComponent }
];

var scrollBehavior = (to, from, savedPosition) => {	// Did not work: scrolling on body/html do nothing, app is in "main" tag! Body is 100% height. TODO Pull request vuejs?
return { x: 0, y: 0 }
}

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
var router = new VueRouter({
    mode: 'history',
    routes // short for routes: routes
});

// const app = new Vue({
// router
// }).$mount('#app')

/* VUEJS INIT HERE */
var vm = new Vue({
    el: '#app',
    router: router,
    data: function() {
        return {
            show: true,
            user: {
        		id: 0,
                user_uuid: 0,
        		username: '?',
        		roles: [{
        			isAdmin: false,
        		}]
        	},
            token: undefined,
            notifications: [
                {
                    body: 'Nouvelle notif sur tel feedback',
                    type: 'newComment'
                }
            ]
        }
    },
    methods: {
        authRedirect(){
            if(!this.token)
            	window.location = AUTH_LINK;
            else {
                this.$router.push({ name: 'user' });
            }
        },
        retrieveUser() {
            var _self = this
            $.ajax({
                url: API_ROUTES.me,
                type: 'POST',
                dataType: 'json',
                data: {
                    app_uuid: sessionStorage.APP_UUID,
                    token: this.token
                },
                success: function(result) {
                    _self.user = result.me
                },
                error: function(req, textStatus, exc){
                    if(req.status === 404) {
                        alertModal('Oups', 'Nous n\'avons pu vous connecter');
                    }
                }
            })
        }
    },
    created(){
        var storage = new CrossStorageClient(CONNECTION_HUB);
        var mainToken = '';
        var _self = this;

        storage.onConnect().then(function() {
            return storage.get('token')
        }).then((t) => {
            if(mainToken = t){
                $.ajax({
                    url: API_ROUTES.authentication,
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        app_uuid: sessionStorage.getItem('APP_UUID'),
                        token: t
                    },
                    success: function(result){
                        _self.token = result.token;
                        _self.retrieveUser()
                    },
                    error: function(req, textStatus, exc){
                        switch(req.status){
                            case HTTP_CODE.UNAUTHORIZED:  // Invalid token or expired
                            break;
                            case HTTP_CODE.BAD_REQUEST:
                            alertModal('Oups', 'La requête est incorrecte.');
                            break;
                            case HTTP_CODE.INTERNAL_SERVER_ERROR:
                            alertModal('Oups', 'Une erreur est survenue!');
                            break;
                            default:
                            alertModal('Oups', 'Une erreur s\'est produite! Code: ' + req.status);
                            console.log(req);
                            console.log(textStatus);
                            console.log(exc);
                        }
                    }
                });
            }
            storage.close();
        });


    },
    computed: {
        adminLevel(){
            if(typeof this.user != 'undefined'){
                return !!this.user.roles.filter(x => x.isAdmin).length;
            }

            return false;
        },
        _token() { // WTF, do not remove. Cause error if not present. No reference to this in the app...
            return this.token
        }
    },
    watch: {
        '$route': function(){
            $("main").animate({ scrollTop: 0 }, "slow");
        },
        user(newVal, oldVal) {
            if(typeof notification.MaterialSnackbar != 'undefined') {
                notification.MaterialSnackbar.showSnackbar({
                    message: 'Bonjour ' + this.user.username + '!'
                });
            }
            else{
                setTimeout(() => {
                    notification.MaterialSnackbar.showSnackbar({
                        message: 'Bonjour ' + this.user.username + '!'
                    });
                }, 1000); // Best protip evar...
            }
        }
    }
});

dialog.querySelector('.close').addEventListener('click', function(e) {
    if(typeof window.dialogRedirect === 'object')
    router.push(window.dialogRedirect);
    dialog.close();
});

/* Retrieve the API KEY for further request */
$.ajax({
    url: API_ROUTES.uuid,
    type: 'GET',
    dataType: 'json',
    success: function(result){
        if(typeof result.api_uuid != 'undefined')
        sessionStorage.APP_UUID = result.api_uuid;
        else
        sessionStorage.APP_UUID = 'not_provided';
    },
    error: function(req, textStatus, exc){
        switch(req.status){
            case HTTP_CODE.BAD_REQUEST:
            alertModal('Oups', 'La requête est incorrecte.');
            break;
            case HTTP_CODE.INTERNAL_SERVER_ERROR:
            alertModal('Oups', 'Une erreur est survenue!');
            break;
            default:
            alertModal('Oups', 'Une erreur s\'est produite! Code: ' + req.status);
            console.log(req);
            console.log(textStatus);
            console.log(exc);
        }
    }
});

function contactAPI(method, route, data, headers, success, error){ // TODO: Implement a centralized contact API method

}

/* Et pour le menu en mode mobile ...
Not natively supported (yet?): https://github.com/google/material-design-lite/issues/1246
*/
$('.mdl-layout__drawer a').click(function(){$('.mdl-layout__drawer,.mdl-layout__obfuscator').toggleClass('is-visible');});
$('.addButtonFb').on('click', function(e){
    router.push({ name: 'feedbackCreate' });
})
