/**
@author: NiceNeko for Neko-san
*/
//

var helpComponent = Vue.extend({
	template: MultiString(function() {/**
		<div class="mainViewCentered">
			<h1><i class="material-icons md-font-large">help_outline</i>Aide</h1>

			<h3><i class="material-icons md-font-medium">keyboard_arrow_right</i>Neko-san Feedback c'est quoi?</h3>
			<span class="para">
				Cette plateforme s'inscrit dans la volonté d'échanger le plus possible avec la communauté Neko-San, vous!
			</span>
			<span class="para">
				En effet, à travers cette dernière nous comptons recueillir vos avis et suggestions en restant le plus efficace possible.
				Ainsi, vous pouvez participer activement à l'amélioration permanente de l'ensemble des aspects de la communauté à travers bien sûr les Feedback en eux même, mais tout autant avec vos commentaires et vos votes!
			</span>

			<h3><i class="material-icons md-font-medium">keyboard_arrow_right</i>Comment poster un Feedback?</h3>
			<span class="para">
				Il vous faut vous connecter.<br />N'importe quelle connexion Neko-San marchera: dès que vous vous connectez au site principal Neko-San.fr, votre compte est réutilisé.<br />Si vous n'avez pas encore de compte, nous vous invitons à en créer un sur <a href="//beta3.neko-san.fr">Neko-San</a>.
			</span>

			<h3><i class="material-icons md-font-medium">keyboard_arrow_right</i>C'est quoi un neko-badge?</h3>
			<span class="para">
				C'est une distinction rare qui est donnée à un feedback lorsque l'équipe Neko-San y reconnaît un intérêt tout particulier. Une telle attribution donne automatiquement +100 en votes!
			</span>

			<h3><i class="material-icons md-font-medium">keyboard_arrow_right</i>Comment marche Feedback N-S?</h3>
			<span class="para">
				Le site est une OPA (One Page Application), celà signifie que vous n'avez pas besoin de recharger la page, les données sont rafraichies en temps réel. Vous pouvez également l'utiliser avec votre mobile!
			</span>

			<h3><i class="material-icons md-font-medium">keyboard_arrow_right</i>Il y a un bug, je fais quoi?</h3>
			<span class="para">
				Le site est encore en bêta, ainsi si vous pensez rencontrer un bug, n'hésitez pas à nous contacter... ou tout simplement créer un nouveau Feedback en catégorie bug!
			</span>
		</div>
	**/}),
	data: function(){
		return {
			show: true
		}
	}
});
