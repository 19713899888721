var API_ROUTES = {
	uuid: 'api/uuid',
	authentication: 'https://api.neko-san.fr/authenticate',
    user: 'https://api.neko-san.fr/users',
    me: 'https://api.neko-san.fr/users/me',
	feedback: 'api/feedback',
	topFeedback: 'api/feedback/top',
	searchFeedback: 'api/feedback/search',
	category: 'api/feedback/category',
	comment: 'api/feedback/comment',
	vote: 'api/feedback/vote',
	lastFeedback: 'api/feedback/last',
	userFeedbacks: 'api/user/feedback',
	unvalidatedFeedback: 'api/feedback/unvalidated'
};
