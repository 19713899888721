/**
@author: NiceNeko for Neko-san
*/

var userComponent = Vue.component('user', {
	template: MultiString(function() {/**
        <div>
            <h2 class="sectionTitle">Votre espace</h2>
    		<div class="mdl-grid">
    			<div class="mdl-cell mdl-cell--6-col-desktop mdl-cell--8-col-tablet mdl-cell--4-col-phone">
    				<span class="topFeedbackTitle">Vos stats</span>
                    <table class="mdl-data-table mdl-js-data-table mdl-shadow--2dp stats-tab">
                        <tbody>
                            <tr>
                                <td class="mdl-data-table__cell--non-numeric">Feedbacks postés</td>
                                <td class="stats-count">{{ feedbackPosted }}</td>
                            </tr>
                            <tr>
                                <td class="mdl-data-table__cell--non-numeric">Commentaires laissés</td>
                                <td class="stats-count">{{ commentPosted }}</td>
                            </tr>
                            <tr>
                                <td class="mdl-data-table__cell--non-numeric">Votes reçus</td>
                                <td class="stats-count">{{ votereceived }}</td>
                            </tr>
                            <tr>
                                <td class="mdl-data-table__cell--non-numeric">Neko-Badge</td>
                                <td class="stats-count">{{ nekobadge }}</td>
                            </tr>
                            <tr>
                                <td class="mdl-data-table__cell--non-numeric">Réputation</td>
                                <td class="stats-count">{{ reputation }}</td>
                            </tr>
                        </tbody>
                        </table>
    			</div>
    			<div class="mdl-cell mdl-cell--6-col-desktop mdl-cell--8-col-tablet mdl-cell--4-col-phone">
    				<span class="topFeedbackTitle">Notifications</span>
                    <i>Bientôt...</i>
    			</div>
    		</div>
    		<div class="mdl-grid">
    			<div class="mdl-cell mdl-cell--6-col-desktop mdl-cell--8-col-tablet mdl-cell--4-col-phone">
    				<span class="topFeedbackTitle">Feedbacks en validation</span>
    				<feedbackList v-bind:feedbacks="feedbackNotValidated" v-bind:blockDesign="blockDesign"></feedbackList>
    			</div>
    			<div class="mdl-cell mdl-cell--6-col-desktop mdl-cell--8-col-tablet mdl-cell--4-col-phone">
    				<span class="topFeedbackTitle">Feedbacks validés</span>
    				<feedbackList v-bind:feedbacks="feedbackValidated" v-bind:blockDesign="blockDesign"></feedbackList>
    			</div>
    		</div>
        </div>
	**/}),
	props: {
		user: {
			type: Object,
			required: true
		},
		token: {
			type: String,
			required: false
		}
	},
	activated(){
		if(this.user.user_uuid == 0){
            this.$router.push({ name: 'home' });
            return;
        }
        this.fetchUserFeedbacks();
        this.fetchUserComments();
        this.fetchUserReputation();
	},
    created: function(){
        this.fetchUserFeedbacks();
        this.fetchUserComments();
        this.fetchUserReputation();
    },
    computed: {
        feedbackValidated(){
            return this.feedbacks.filter(x => x.approved);
        },
        feedbackNotValidated(){
            return this.feedbacks.filter(x => !x.approved);
        },
        feedbackPosted(){
            return this.feedbacks.length;
        },
        commentPosted(){
            return this.comments.length;
        }
    },
	methods: {
		fetchUserFeedbacks(){
			var _self = this;
            if(!this.token)
                return false;
			$.getJSON(API_ROUTES.userFeedbacks, {
                token: _self.token
            })
            .done(function(data_feedbacks){
                if(data_feedbacks.length > 0){
                    var list_uuid = $.unique(data_feedbacks.map(function(fb) { return fb.user_uuid; }));

                    $.ajax({     // Get users
                        url: API_ROUTES.user,
                        type: 'POST',
                        data: {
                            user_uuid_list: list_uuid.join(','),
                            app_uuid: sessionStorage.APP_UUID
                        },
                        success: function(data_users){
                            var _users = [];
                            for(index in data_users.user){
                                _users[data_users.user[index].user_uuid] = data_users.user[index];
                            }
                            for(index in data_feedbacks){
                                data_feedbacks[index].user = _users[data_feedbacks[index].user_uuid];
                            }
                            _self.feedbacks = data_feedbacks;  // Merge users data and feedbacks
                        },
                        error: function(err){
                            alertModal('Oups', 'Une erreur est survenue pendant la récupération des données. Si le problème persiste, contactez un administrateur avec le code suivant: ' + err.status, { name: 'home' });
                            console.log('Server error:');
                            console.log(err);
                        }
                    });
                }
            }).fail(function(err){
                if(err.status === HTTP_CODE.TOO_MANY_REQUESTS){
                    alertModal('Attention', 'Merci de ne pas spammer l\'interface! Pour des raisons de sécurité, vous avez été bloqué quelques secondes.');
                }
                else if(err.status === HTTP_CODE.NOT_FOUND){
                    _self.feedbacks = [];
                }
                else{
                    alertModal('Erreur', 'Erreur serveur! Code ' + err.status);
                    console.log('Server error:');
                    console.log(err);
                }
            });
		},
        fetchUserComments(){
			var _self = this;
            if(!this.token)
                return false;
            $.getJSON('api/user/' + this.user.user_uuid + '/comment', {
                token: _self.token
            })
            .done(function(data){
                if(data.length > 0){
                    _self.comments = data;
                }
            })
            .fail(function(err){
                if(err.status === HTTP_CODE.TOO_MANY_REQUESTS){
                    alertModal('Attention', 'Merci de ne pas spammer l\'interface! Pour des raisons de sécurité, vous avez été bloqué quelques secondes.');
                }
                else if(err.status === HTTP_CODE.NOT_FOUND){
                    _self.comments = [];
                }
                else{
                    alertModal('Erreur', 'Erreur serveur! Code ' + err.status);
                    console.log('Server error:');
                    console.log(err);
                }
            });
		},
        fetchUserReputation(){
			var _self = this;
            if(!this.token)
                return false;
            $.getJSON('api/user/' + this.user.user_uuid + '/reputation')
            .done(function(data){
                if(typeof data === 'object'){
                    _self.reputation = data.reputation;
                    _self.nekobadge = data.nekobadge;
                    _self.votereceived = data.votereceived;
                }
            })
            .fail(function(err){
                if(err.status === HTTP_CODE.TOO_MANY_REQUESTS){
                    alertModal('Attention', 'Merci de ne pas spammer l\'interface! Pour des raisons de sécurité, vous avez été bloqué quelques secondes.');
                }
                else if(err.status === HTTP_CODE.NOT_FOUND){
                    _self.reputation = 0;
                }
                else{
                    alertModal('Erreur', 'Erreur serveur! Code ' + err.status);
                    console.log('Server error:');
                    console.log(err);
                }
            });
		}
	},
	data: function(){
		return {
			feedbacks: [],
            comments: [],
            reputation: 0,
            nekobadge: 0,
            votereceived: 0,
			blockDesign: true
		}
	}
});
