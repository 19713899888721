/**
	@author: NiceNeko for Neko-san
*/

var feedbackPostComponent = Vue.component('feedbackPost', {
	template: MultiString(function() {/**
	<div class="dynMargin">
		<div class="feedbackPost mdl-shadow--2dp">
			<div class="mdl-grid section--center">
				<div class="voteblock mdl-cell mdl-cell--1-col">
					<i class="fa fa-chevron-up fa-vote-arrow" aria-hidden="true" v-on:click="vote(1)"></i>
					<div class="bubbleVotePost" :class="colorVoteClass">
						<p>{{ animatedNote }}</p>
					</div>
					<i class="fa fa-chevron-down fa-vote-arrow" aria-hidden="true" v-on:click="vote(-1)"></i>
				</div>
				<div class="mdl-cell mdl-cell--11-col-desktop mdl-cell--3-col-phone">
					<span v-if="isNew()" class="label label-danger">New</span>&nbsp;
					<span class="feedbackTitle">{{ title }}</span><small>&nbsp;-&nbsp;Par {{ author.username }}, il y a {{ deltaPostedValue }} {{ deltaPostedUnit }}.</small>
					<span class="bodyMessage" v-html="bodyMessage"></span>
				</div>
				<div class="mdl-cell mdl-cell--12-col rightOnCell">
					<!-- Category Chip -->
					<span class="mdl-chip mdl-chip--contact">
						<span class="mdl-chip__contact mdl-color--teal mdl-color-text--white">{{ category.name | firstLetter }}</span>
						<span class="mdl-chip__text">{{ category.name }}</span>
					</span>
				</div>
				<div class="mdl-cell mdl-cell--12-col rightOnCell">
                    <a v-if="isAdmin() && !approved" v-on:click="validateFeedback">Valider</a>
                    <a v-if="isAdmin() || isOwner()" v-on:click="editFeedback">Editer</a>
                    <a v-if="isAdmin() || isOwner()" v-on:click="deleteFeedback">Supprimer</a>
                    <span class="viewed">{{ computedViewed }}</span>
				</div>
			</div>
		</div>
		<section class="comments">
			<comment
				v-for="(comment, index) in comments"
				v-bind:comment="comment"
				v-bind:index="index"
				:key="comment.id">
			</comment>
		</section>
		<div class="createComContainer">
			<div class="mdl-textfield mdl-js-textfield mdl-js-dyn">
				<textarea class="mdl-textfield__input" type="text" rows="3" id="commentCreate" v-model="userComment" :maxlength="maxlengthComment"></textarea>
				<label class="mdl-textfield__label" for="commentCreate">Votre commentaire</label>
			</div>
			<button class="mdl-button mdl-js-button mdl-button--accent mdl-js-ripple-effect mdl-js-dyn" v-on:click="submitComment">
				Envoyer
			</button>
		</div>
	</div>
	**/}),
	props: {
		user: {
			type: Object,
			required: true
		},
		token: {
			required: false
		}
	},
	filters: {
		firstLetter: function (value){
			if(!value)
				return '';
			return value[0];
		}
	},
    mounted: function(){
		this.ticker = (performance.timing.navigationStart + performance.now());	// Let's call these "time ago" without waiting for the setinterval to be triggered (better for UX)
		// Dummy data for arbitrary updates
		var _self = this;

        setInterval(function() {
            _self.$data.ticker = (performance.timing.navigationStart + performance.now());
        }, REFRESH_DELAY);

		if(typeof componentHandler !== 'undefined'){ // Not needed at startup (+ cause error as the lib is not loaded yet at this point)
			componentHandler.upgradeElements(document.querySelectorAll('.mdl-js-dyn'));
		}
    },
	activated(){
		this.fetch();
		this.fetchComments();
	},
    computed: {
        computedViewed(){
            return this.viewed + ' vue' + ((this.viewed > 1) ? 's' : '');
        }
    },
	/*propsData:{
		comments: this.comments
	},*/
	data: function(){
		return {
			id: '',
			title: '',
			bodyMessage: '',
			created_at: '',
			updated_at: '',
			author: {
                username: ''
            },
			category: [],
			note: 0,
			approved: false,
			approvedOn: '',
			colorVoteClass: 'voteDefault',
			/* Comments data */
			comments: [],
			animatedNote: 0,
			/* User comment */
			userComment: '',
			deltaPostedValue: '0',
			deltaPostedUnit: '',
			// "foreign" data
			ticker: (performance.timing.navigationStart + performance.now()),	// Trigger interface
			deltaPostedStr: '',	// Data posted x (time) ago  (/!\ String: unit is dynamically determined)
			user_uuid: 0,
			viewed: 0,
            maxlengthComment: MAX_COMMENT_SIZE
		}
	},
	methods:{
		isNew(){
			return (((this.ticker - new Date(this.created_at)) / 1000) < TIME_NOTIONS.day);
		},
		isOwner(){
			return (this.user_uuid) ? this.user.user_uuid === this.user_uuid : false;
		},
		isAdmin(){
			if(typeof this.user != 'undefined'){
				return !!this.user.roles.filter(x => x.isAdmin).length;
			}

			return false;
		},
		vote(type){
			if(typeof this.$route.params.feedbackid  == 'undefined')
				return;
			var _self = this;
			$.ajax({
			    url: API_ROUTES.feedback + '/' + _self.$route.params.feedbackid + '/vote',
			    type: 'POST',
				data: {
			 		type: type,
                    token: this.token
				},
				success: function(data){
					_self.note = data.newNote;
				},
				error: function(err){
    				if(err.status == HTTP_CODE.UNAUTHORIZED){
					    alertModal('Connectez-vous', 'Vous devez être connecté pour effectuer un vote!');
                        return;
                    }
                   	alertModal('Oups', 'Une erreur est survenue pendant la récupération des données. Si le problème persiste, contactez un administrateur avec le code suivant: ' + err.status, { name: 'home' });
					console.log('Server error:');
					console.log(err);
				}
			});
		},
		fetch(){
			if(typeof this.$route.params.feedbackid == 'undefined')
				return;
			var _self = this;
			$.getJSON(API_ROUTES.feedback + '/' + _self.$route.params.feedbackid, {
                token: this.token
            })
			.done(function(data){
				_self.id = data.id;
				_self.title = data.title;
				_self.bodyMessage = data.bodyMessage;
				_self.created_at = data.created_at;
				_self.updated_at = data.updated_at;
				_self.category = data.category;
				_self.note = data.note;
				_self.approved = data.approved;
				_self.approvedOn = data.approvedOn;
				_self.user_uuid = data.user_uuid;
				_self.viewed = data.viewed;
				_self.ticker = (performance.timing.navigationStart + performance.now());

    			$.ajax({
                    url: API_ROUTES.user,
                    type: 'POST',
                    data: {
                        user_uuid_list: data.user_uuid,
                        app_uuid: sessionStorage.APP_UUID
                    },
                    success: function(data) {
                        _self.author = data.user[0] || {
                            user_uuid: 'mlemle',
                            username: 'Ancien utilisateur'
                        }
                    },
                    error: function(err) {
                        if(err.status === 404) {
                            _self.author = {
                               user_uuid: 'mlemle',
                               username: 'Ancien utilisateur'
                           }
                       } else {
                           console.warn(err)
                       }
                    }
                })
			}).fail(function(err){
				if(err.status == HTTP_CODE.NOT_FOUND){
					alertModal('Dis donc!', 'Ce feedback n\'existe pas!', { name: 'home' });
				}
				else{
					alertModal('Oups', 'Une erreur est survenue pendant la récupération des données. Si le problème persiste, contactez un administrateur avec le code suivant: ' + err.status, { name: 'home' });
					console.log('Server error:');
					console.log(err);
				}
			});
		},
		fetchComments(){
			var _self = this;
			$.getJSON(API_ROUTES.feedback + '/' + _self.$route.params.feedbackid + '/comment', { })
			.done(function(dataComments, err){
                if(dataComments.length > 0) {
                    var list_uuid = $.unique(dataComments.map(function(c) { return c.user_uuid; }));

                    $.ajax({     // Get users
                        url: API_ROUTES.user,
                        type: 'POST',
                        data: {
                            user_uuid_list: list_uuid.join(','),
                            app_uuid: sessionStorage.APP_UUID
                        },
                        success: function(data_users){
                            var _users = [];
                            for(index in data_users.user){
                                _users[data_users.user[index].user_uuid] = data_users.user[index];
                            }
                            for(index in dataComments){
                                dataComments[index].user = _users[dataComments[index].user_uuid];
                            }
                            _self.comments = dataComments;  // Merge users data and comments
                        },
                        error: function(err){
                            if(err.status !== 404) {
                                alertModal('Oups', 'Une erreur est survenue pendant la récupération des données. Si le problème persiste, contactez un administrateur avec le code suivant: ' + err.status, { name: 'home' });
                            }
                            console.log('Server error:');
                            console.log(err);
                        }
                    });
                } else {
                    _self.comments = []
                }
			}).fail(function(err){
				if(err.status === HTTP_CODE.NOT_FOUND)
					return;
				alertModal('Oups', 'Une erreur est survenue pendant la récupération des données. Si le problème persiste, contactez un administrateur avec le code suivant: ' + err.status, { name: 'home' });
				console.log('Server error:');
				console.log(err);
			});
		},
		fetchVotes(){
			var _self = this;
			$.getJSON(API_ROUTES.feedback + '/' +  _self.$route.params.feedbackid + '/vote', { })
			.done(function(data){
				_self.feedbacks = data;
			}).fail(function(err){
				alertModal('Oups', 'Une erreur est survenue pendant la récupération des données. Si le problème persiste, contactez un administrateur avec le code suivant: ' + err.status, { name: 'home' });
				console.log('Server error:');
				console.log(err);
			});
		},
		submitComment(){
			if(this.userComment == ''){
				alertModal('Néant détecté', 'Votre commentaire sur un feedback doit être un minimum constructif ;)');
				return;
			}
			if(this.userComment.length > MAX_COMMENT_SIZE){
				alertModal('Aïe', 'Votre commentaire est trop long! Merci de ne pas dépasser '+ MAX_COMMENT_SIZE +' caractères!');
				return;
			}
			if(this.user_uuid === 0){
				alertModal('Connectez-vous!', 'Vous devez être connecté pour laisser un commentaire.');
				return;
			}
			var _self = this;
			$.ajax({
			    url: API_ROUTES.feedback + '/' + _self.$route.params.feedbackid + '/comment',
			    type: 'POST',
				data: {
			 		'bodyMessage': _self.userComment,
                    token: this.token
				},
				success: function(data, textStatus, xhrobj){
					if(xhrobj.status === HTTP_CODE.CREATED){
						_self.fetchComments();
						_self.userComment = '';
					}
					else {
						alertModal('Oups', 'Une erreur est survenue pendant la récupération des données. Si le problème persiste, contactez un administrateur avec le code suivant: ' + xhrobj.status, { name: 'home' });
					}
				},
				error: function(err){
    				if(err.status == HTTP_CODE.UNAUTHORIZED){
                        if(err.responseJSON.error === "token_not_valid"){
					        alertModal('Session expirée!', 'Vous devez vous reconnecter pour laisser un commentaire.');
                            this.token = undefined;
                            this.user = getGenericUser();
                        }
                        else {
                            alertModal('Connectez-vous!', 'Vous devez être connecté pour laisser un commentaire.');
                        }
                        return;
                    }
					if(err.status === HTTP_CODE.UNPROCESSABLE_ENTITY && err.responseJSON.bodyMessage[0] === 'validation.max.string'){
						alertModal('Oups', 'Votre message est trop long! Merci de ne pas dépassez '+ MAX_COMMENT_SIZE +' caractères!');
						return;
					}
					alertModal('Oups', 'Une erreur est survenue pendant la récupération des données. Si le problème persiste, contactez un administrateur avec le code suivant: ' + err.status, { name: 'home' });
					console.log('Server error:');
					console.log(err);
				}
			});
		},
		animateNote(newValue, oldValue){
			if(newValue > 0)
				this.colorVoteClass = 'votePositif';
			else if(newValue < 0)
				this.colorVoteClass = 'voteNegatif';
			else
				this.colorVoteClass = 'voteDefault';
			oldValue = oldValue || 0;
	    	var vm = this;
    		function animate (time) {
        		requestAnimationFrame(animate);
        		TWEEN.update(time);
    		}
	    	new TWEEN.Tween({ tweeningNumber: oldValue })
		        .easing(TWEEN.Easing.Quadratic.Out)
		        .to({ tweeningNumber: newValue }, GAUGE_DELAY_ANIMATION)
		        .onUpdate(function () {
		        	vm.animatedNote = this.tweeningNumber.toFixed(0)
		        }).start();
		    animate();
		},
		deleteFeedback(){
			var _self = this;
			$.ajax({
				url: API_ROUTES.feedback + '/' + _self.$route.params.feedbackid,
				type: 'DELETE',
				dataType: 'json',
				data: {
                    token: this.token
                },
				success: function(data, textStatus, xhrobj){
					if(xhrobj.status === HTTP_CODE.FOUND_OK){
						alertModal('Supprimé', 'Ce feedback a été effacé.', { name: 'home' });
					}
					else {
						alertModal('Oups', 'Une erreur est survenue pendant la récupération des données. Si le problème persiste, contactez un administrateur avec le code suivant: ' + xhrobj.status, { name: 'home' });
					}
				},
				error: function(err){
					if(err.status === HTTP_CODE.UNPROCESSABLE_ENTITY && err.responseJSON.bodyMessage[0] === 'validation.max.string'){
						alertModal('Oups', 'Votre message est trop long! Merci de ne pas dépassez '+ MAX_COMMENT_SIZE +' caractères!');
						return;
					}
					alertModal('Oups', 'Une erreur est survenue pendant la récupération des données. Si le problème persiste, contactez un administrateur avec le code suivant: ' + err.status, { name: 'home' });
					console.log('Server error:');
					console.log(err);
				}
			});
		},
		validateFeedback(){
			var _self = this;
			$.ajax({
				url: API_ROUTES.feedback + '/' + _self.$route.params.feedbackid,
				type: 'PUT',
				dataType: 'json',
				data: {
					approved: 1,
                    token: this.token
				},
				success: function(data, textStatus, xhrobj){
					if(xhrobj.status === HTTP_CODE.FOUND_OK){
						alertModal('Validé', 'Ce feedback vient d\'être validé! Il est maintenant visible pour tous les utilisateurs.');
						_self.fetch();
					}
					else {
						alertModal('Oups', 'Une erreur est survenue pendant la récupération des données. Si le problème persiste, contactez un administrateur avec le code suivant: ' + xhrobj.status, { name: 'home' });
					}
				},
				error: function(err){
					if(err.status === HTTP_CODE.UNPROCESSABLE_ENTITY && err.responseJSON.bodyMessage[0] === 'validation.max.string'){
						alertModal('Oups', 'Votre message est trop long! Merci de ne pas dépassez '+ MAX_COMMENT_SIZE +' caractères!');
						return;
					}
					alertModal('Oups', 'Une erreur est survenue pendant la récupération des données. Si le problème persiste, contactez un administrateur avec le code suivant: ' + err.status, { name: 'home' });
					console.log('Server error:');
					console.log(err);
				}
			});
		},
        editFeedback(){
            this.$router.push({ name: 'feedbackEdit', params: { feedbackid: this.$route.params.feedbackid }});
        }
	},
	route: {
		data(){
	        console.log('The current ID is ' + this.$route.params.feedbackid);
	    }
	},
	watch: {
		note: function(newValue, oldValue){
			if(newValue > 0)
				this.colorVoteClass = 'votePositif';
			else if(newValue < 0)
				this.colorVoteClass = 'voteNegatif';
			else
				this.colorVoteClass = 'voteDefault';
			if( Math.abs(newValue - oldValue) > 1 )
				this.animateNote(newValue, oldValue);
			else
				this.animatedNote = newValue;
		},
		ticker: function(val){ // Called every second
			var delta = (val - (new Date(this.created_at))) / 1000;
			var unit = '';

			if(delta < TIME_NOTIONS.minute){
				unit = 'seconde';
			}
			else if(delta < TIME_NOTIONS.hour){
				unit = 'minute';
				delta /= TIME_NOTIONS.minute;
			}
			else if(delta < TIME_NOTIONS.day){
				unit = 'heure';
				delta /= (TIME_NOTIONS.hour);
			}
			else if(delta < TIME_NOTIONS.month){
				unit = 'jour';
				delta /= (TIME_NOTIONS.day);
			}
			else if(delta < TIME_NOTIONS.year){
				unit = 'mois';
				delta /= (TIME_NOTIONS.month);
			}
			else{
				unit = 'année';
				delta /= (TIME_NOTIONS.year);
			}

			delta = Math.round(delta);

			if(delta > 1 && unit[unit.length-1] != 's')
				unit += 's';
			this.deltaPostedValue = delta;
			this.deltaPostedUnit = unit;
		}
	}
})
