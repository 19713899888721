/**
@author: NiceNeko for Neko-san
*/

var topFeedbackComponent = Vue.component('topFeedback', {
	template: MultiString(function() {/**
		<div class="mdl-cell mdl-cell--4-col-desktop mdl-cell--8-col-tablet mdl-cell--4-col-phone topBlock">
			<span class="topFeedbackTitle">Top 5 Feedbacks</span>
			<feedbackList v-bind:feedbacks="feedbacks" v-bind:blockDesign="blockDesign"></feedbackList>
		</div>
	**/}),

	props: {
		refreshHook: {
			type: String,
			required: true
		}
	},
    created: function(){
        this.fetchFeedbacks();
    },
	methods:{
		fetchFeedbacks(){
			var _self = this;
			$.getJSON(API_ROUTES.topFeedback)
            .done(function(data_feedbacks){
                if(data_feedbacks.length > 0){
                    var list_uuid = $.unique(data_feedbacks.map(function(fb) { return fb.user_uuid; })).join(',');

                    $.ajax({     // Get users
                        url: API_ROUTES.user,
                        type: 'POST',
                        data: {
                            user_uuid_list: list_uuid,
                            token: _self.token,
                            app_uuid: sessionStorage.APP_UUID
                        },
                        success: function(data_users){
                            var _users = [];
                            for(index in data_users.user){
                                _users[data_users.user[index].user_uuid] = data_users.user[index];
                            }
                            for(index in data_feedbacks){
                                data_feedbacks[index].user = _users[data_feedbacks[index].user_uuid];
                            }
                            _self.feedbacks = data_feedbacks;  // Merge users data and feedbacks
                        },
                        error: function(err){
                            alertModal('Oups', 'Une erreur est survenue pendant la récupération des données. Si le problème persiste, contactez un administrateur avec le code suivant: ' + err.status, { name: 'home' });
                            console.log('Server error:');
                            console.log(err);
                        }
                    });
                }
			}).fail(function(err){
				alert('Server error!');
				console.log('Server error:');
				console.log(err);
			});
		},
		/*getNotes(){
			var len = this.feedbacks.length;
			var _self = this;
			for(var i=0; i < len; i++){
				$.ajax({
					url: 'api/feedback/'+ _self.feedbacks[i].id +'/votes',
					dataType: 'json',
					context: { position: i }
				}).done(function(data){
					var sum = 0;
					for(var index in data)
						sum += data[index].type;
					_self.feedbacks[this.position].note = sum;
				}).fail(function(err){
					alert('Server error!');
					console.log('Server error:');
					console.log(err);
				});
			}
		}*/
	},
	data: function(){
		return {
			searchValue: '',
			feedbacks: [],
			blockDesign: true
		}
	},
	watch: {
		'$route': function(){
			if(this.$route.name == 'home')
				this.fetchFeedbacks();
		},
		refreshHook(){
			this.fetchFeedbacks();
		}
	}
});
