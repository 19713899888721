/**
@author: NiceNeko for Neko-san
*/

var adminComponent = Vue.component('admin', {
	template: MultiString(function() {/**
		<div class="mdl-grid">
			<div class="mdl-cell mdl-cell--6-col-desktop mdl-cell--8-col-tablet mdl-cell--4-col-phone">
				<span class="topFeedbackTitle">FB en attente</span>
				<feedbackList v-bind:feedbacks="feedbacks" v-bind:blockDesign="blockDesign"></feedbackList>
			</div>
			<div class="mdl-cell mdl-cell--6-col-desktop mdl-cell--8-col-tablet mdl-cell--4-col-phone">
				<span class="topFeedbackTitle">Commentaires en attente</span>
			</div>
		</div>
	**/}),
	props: {
		user: {
			type: Object,
			required: true
		},
		token: {
			type: String,
			required: false
		}
	},
	activated(){
		if(this.user.roles.filter(x => x.isAdmin).length < 1){
            this.$router.push({ name: 'home' });
            return;
        }
        this.fetchFreshFeedbacks();
	},
    created: function(){
        this.fetchFreshFeedbacks();
    },
	methods: {
		fetchFreshFeedbacks(){
			var _self = this;
            if(!this.token)
                return false;
			$.getJSON(API_ROUTES.unvalidatedFeedback, {
                token: _self.token
            })
            .done(function(data_feedbacks){
                if(data_feedbacks.length > 0){
                    var list_uuid = $.unique(data_feedbacks.map(function(fb) { return fb.user_uuid; }));

                    $.ajax({     // Get users
                        url: API_ROUTES.user,
                        type: 'POST',
                        data: {
                            user_uuid_list: list_uuid.join(','),
                            app_uuid: sessionStorage.APP_UUID
                        },
                        success: function(data_users){
                            var _users = [];
                            for(index in data_users.user){
                                _users[data_users.user[index].user_uuid] = data_users.user[index];
                            }
                            for(index in data_feedbacks){
                                data_feedbacks[index].user = _users[data_feedbacks[index].user_uuid];
                            }
                            _self.feedbacks = data_feedbacks;  // Merge users data and feedbacks
                        },
                        error: function(err){
                            alertModal('Oups', 'Une erreur est survenue pendant la récupération des données. Si le problème persiste, contactez un administrateur avec le code suivant: ' + err.status, { name: 'home' });
                            console.log('Server error:');
                            console.log(err);
                        }
                    });
                }
            }).fail(function(err){
                if(err.status === HTTP_CODE.TOO_MANY_REQUESTS){
                    alertModal('Attention', 'Merci de ne pas spammer l\'interface! Pour des raisons de sécurité, vous avez été bloqué quelques secondes.');
                }
                else if(err.status === HTTP_CODE.NOT_FOUND){
                    _self.feedbacks = [];
                }
                else{
                    alertModal('Erreur', 'Erreur serveur! Code ' + err.status);
                    console.log('Server error:');
                    console.log(err);
                }
            });
		}
	},
	data: function(){
		return {
			feedbacks: [],
			blockDesign: true
		}
	}
});
