/**
@author: NiceNeko for Neko-san
*/

var homeComponent = Vue.extend({
	template: MultiString(function() {/**
		<div class="mdl-grid">
			<feedbackBoard v-bind:refreshHook="refreshHook"></feedbackBoard>
			<topFeedback v-bind:refreshHook="refreshHook"></topFeedback>
		</div>
	**/}),
	mounted(){
		setInterval(this.isThereNewData, CHECK_DELAY);
	},
	methods: {
		isThereNewData(){
			var _self = this;
			$.getJSON(API_ROUTES.lastFeedback, {})
			.done(function(data){
				if(localStorage.lastFeedback != data.id){	// Using sessionStorage to avoid multi-notification from multiple tab on our website.
					if(localStorage.lastFeedback != '' && typeof localStorage.lastFeedback != 'undefined')
						notifyMe(data.title, data.bodyMessage.toString().substr(0, LIMITATION_DESC_CHAR).replace( /<.*?>/g, ''), { name: 'feedbackPost', params: { feedbackid: data.id } });
					_self.refreshHook = data.id;
					localStorage.lastFeedback = data.id;
				}
				//_self.getNotes();
			}).fail(function(err){
				console.log('Server error:');
				console.log(err);
			});
		}
	},
	data: function(){
		return {
			show: true,
			refreshHook: ''	// Change this value results in refreshing all the remote data child
		}
	}
});
