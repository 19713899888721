/**
	@author: NiceNeko for Neko-san
*/

var feedbackComponent = Vue.component('feedback', {
	template: MultiString(function() {/**
				<li class="mdl-list__item mdl-list__item--three-line anim">
					<span class="mdl-list__item-primary-content">
						<span class="bubbleVote" :class="colorVoteClass">
							<p>{{ feedb.note }}</p>
						</span>
						<span v-if="isNew()" class="label label-danger">New</span>
						<router-link :to="{ name: 'feedbackPost', params: { feedbackid: feedb.id }  }">
							<span class="feedbackTitle">{{ feedb.title }}</span>
						</router-link>

						<span class="mdl-list__item-text-body">
							<b>Par {{ feedb.user.username }}, il y a {{ deltaPostedValue }} {{ deltaPostedUnit }}</b> -&nbsp;
							{{ tagStrippedBodyMess | limitMsg }}
						</span>
					</span>
					<span class="mdl-list__item-secondary-content catBadge mdl-cell--hide-phone">
						<!-- Category Chip -->
						<span class="mdl-chip mdl-chip--contact" v-bind:class="{ 'startDisplay': useAnimation }">
							<span class="mdl-chip__contact mdl-color--teal mdl-color-text--white">{{ feedb.category.name | firstLetter }}</span>
							<span class="mdl-chip__text">{{ feedb.category.name }}</span>
						</span>
					</div>
				</li>
	**/}),

	props: {
		feedb: {
			type: Object,
			required: true
		},
		useAnimation:{
			type: Boolean,
			required: false,
			default: false
		}
	},
	filters: {
		limitMsg: function (value){
			if(!value)
				return '';
			var ret = value.toString().substr(0, LIMITATION_DESC_CHAR);
			if(ret.length >= LIMITATION_DESC_CHAR)
				ret += '...';
			return ret;
		},
		firstLetter: function (value){
			if(!value)
				return '';
			return value[0];
		}
	},
    mounted: function(){
		this.$data.ticker = (performance.timing.navigationStart + performance.now());	// Let's call these "time ago" without waiting for the setinterval to be triggered (better for UX)
		// Dummy data for arbitrary updates
		var _self = this;

        setInterval(function() {
            _self.ticker = (performance.timing.navigationStart + performance.now());
        }, REFRESH_DELAY);
		// this.animateNote(this.feedb.note);
		// this.animateDate(this.deltaPostedValue);

		this.updateNoteColor();
    },
    updated: function(){	// When changing the data while searching (always remember instance component are not destroyed when overridding data property)
		// foreign data
		//this.ticker = (performance.timing.navigationStart + performance.now());	// Trigger interface
		//this.deltaPostedValue = '';	// Not needed: automatically updated with primary data
		this.updateNoteColor();
    },
	data: function(){
		return {
			colorVoteClass: 'voteDefault',
			// foreign data
			ticker: (performance.timing.navigationStart + performance.now()),	// Trigger interface
			deltaPostedValue: 0,	// Data posted x (time) ago  (/!\ String: unit is dynamically determined)
			deltaPostedUnit: '',
			animatedDate: '',
			animatedNote: '0'
		}
	},
	computed: {
		tagStrippedBodyMess(){
			return decodeEntities(this.feedb.bodyMessage);
		}
	},
	methods: {
		isNew: function(){
			return (((this.ticker - new Date(this.feedb.created_at))/ 1000) < TIME_NOTIONS.day);
		},
		updateNoteColor(){
			/* Class color note */
			if(this.feedb.note > 0)
				this.colorVoteClass = 'votePositif';
			else if(this.feedb.note < 0)
				this.colorVoteClass = 'voteNegatif';
			else
				this.colorVoteClass = 'voteDefault';
		},
		/*animateNote(newValue, oldValue){
			if(newValue > 0)
				this.colorVoteClass = 'votePositif';
			else if(newValue < 0)
				this.colorVoteClass = 'voteNegatif';
			else
				this.colorVoteClass = 'voteDefault';
			oldValue = oldValue || 0;
	    	var vm = this;
    		function animate (time) {
        		requestAnimationFrame(animate);
        		TWEEN.update(time);
    		}
	    	new TWEEN.Tween({ tweeningNumber: oldValue })
		        .easing(TWEEN.Easing.Quadratic.Out)
		        .to({ tweeningNumber: newValue }, 2000)
		        .onUpdate(function () {
		        	vm.animatedNote = this.tweeningNumber.toFixed(0)
		        }).start();
		    animate();
		},
		animateDate(newValue, oldValue){
			oldValue = oldValue || 0;
	    	var vm = this;
    		function animate (time) {
        		requestAnimationFrame(animate);
        		TWEEN.update(time);
    		}
	    	new TWEEN.Tween({ tweeningNumber: oldValue })
		        .easing(TWEEN.Easing.Quadratic.Out)
		        .to({ tweeningNumber: newValue }, 2000)
		        .onUpdate(function () {
		        	vm.animatedDate = this.tweeningNumber.toFixed(0)
		        }).start();
		    animate();
		}*/
	},
	watch: {
		/*deltaPostedValue: function(newValue, oldValue) {
	    	this.animateDate(newValue, oldValue);
	    },*/
		/*'feedb.note': function(newValue, oldValue){
			console.log(newValue);
			if(newValue > 0)
				this.colorVoteClass = 'votePositif';
			else if(newValue < 0)
				this.colorVoteClass = 'voteNegatif';
			else
				this.colorVoteClass = 'voteDefault';
			this.animateNote(newValue, oldValue);
		},*/
		ticker: function(val){ // Called every second
			var delta = (val - (new Date(this.feedb.created_at))) / 1000;
			var unit = '';

			if(delta < TIME_NOTIONS.minute){
				unit = 'seconde';
			}
			else if(delta < TIME_NOTIONS.hour){
				unit = 'minute';
				delta /= TIME_NOTIONS.minute;
			}
			else if(delta < TIME_NOTIONS.day){
				unit = 'heure';
				delta /= (TIME_NOTIONS.hour);
			}
			else if(delta < TIME_NOTIONS.month){
				unit = 'jour';
				delta /= (TIME_NOTIONS.day);
			}
			else if(delta < TIME_NOTIONS.year){
				unit = 'mois';
				delta /= (TIME_NOTIONS.month);
			}
			else{
				unit = 'année';
				delta /= (TIME_NOTIONS.year);
			}

			delta = Math.round(delta);

			if(delta > 1 && unit[unit.length-1] != 's')
				unit += 's';

			this.deltaPostedValue = delta;
			this.deltaPostedUnit = unit;
		}
	}
});
