/**
	@author: NiceNeko for Neko-san
*/

var feedbackCommentComponent = Vue.component('comment', {
	template: MultiString(function() {/**
		<div class="mdl-grid">
			<div class="mdl-cell mdl-cell--2-col-desktop mdl-cell--2-col-tablet mdl-cell--1-col-phone">
				<div :class="'commentAv user-' + author.user_uuid"></div>
			</div>
			<div class="mdl-cell mdl-cell--10-col-desktop mdl-cell--6-col-tablet mdl-cell--3-col-phone">
				<span class="">Par {{ author.username }}, il y a {{ deltaPostedStr }}.</span>
				<span class="bodyMessage" v-html="nl2br(htmlEntities(bodyMessage))"></span>
			</div>
		</div>
	**/}),
	props: {
		comment: {
			type: Object,
			required: true
		}
	},
    updated: function(){	// When changing the data while searching (always remember instance component are not destroyed when overridding data property)
		this.id = this.comment.id;
		this.title = this.comment.title;
		this.bodyMessage = this.comment.bodyMessage;
		this.created_at = this.comment.created_at;
		this.updated_at = this.comment.updated_at;
		this.author = this.comment.user || {
            user_uuid: 'mlemle',
            username: 'Ancien utilisateur'
        };
        if(this.author.avatar) {
            $('.user-'+this.author.user_uuid).css('background-image', 'url('+ AVATAR_BASE + this.author.avatar +')');
        } else {
            $('.user-'+this.author.user_uuid).css('background-image', 'url('+ AVATAR_BASE +'default_gray.png)');
        }

		// foreign data
		this.ticker = (performance.timing.navigationStart + performance.now());	// Trigger interface
		//this.deltaPostedStr = '';	// Not needed: automatically updated with primary data
    },
    created: function(){	// TODO URG: make call to fetch when empty data (= direct url access)
		this.$data.ticker = (performance.timing.navigationStart + performance.now());	// Let's call these "time ago" without waiting for the setinterval to be triggered (better for UX)
		// Dummy data for arbitrary updates
		var _self = this;

        setInterval(function() {
            _self.$data.ticker = (performance.timing.navigationStart + performance.now());
        }, REFRESH_DELAY);
    },
	data: function(){
		return {
			id: this.comment.id,
			bodyMessage: this.comment.bodyMessage,
			avatar: this.comment.avatar,
			created_at: this.comment.created_at,
			updated_at: this.comment.updated_at,
			user_id: this.comment.user_id,
			author: this.comment.user || {
                user_uuid: 'mlemle',
                username: 'Ancien utilisateur'
            },
			// "foreign" data
			ticker: (performance.timing.navigationStart + performance.now()),	// Trigger interface
			deltaPostedStr: ''	// Data posted x (time) ago  (/!\ String: unit is dynamically determined)
		};
	},
	methods: {
		isNew: function (){
			return (((this.ticker - new Date(this.created_at))/ 1000) < (60*60*24));
		},
        nl2br: function (str, isXhtml) {
            var breakTag = (isXhtml || typeof isXhtml === 'undefined') ? '<br />' : '<br>'
            return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
        },
        htmlEntities(str) {
            return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;')
        }
	},
	watch: {
		ticker: function(val){ // Called every second
			var delta = (val - (new Date(this.created_at))) / 1000;
			var unit = '';

			if(delta < TIME_NOTIONS.minute){
				unit = 'seconde';
			}
			else if(delta < TIME_NOTIONS.hour){
				unit = 'minute';
				delta /= TIME_NOTIONS.minute;
			}
			else if(delta < TIME_NOTIONS.day){
				unit = 'heure';
				delta /= (TIME_NOTIONS.hour);
			}
			else if(delta < TIME_NOTIONS.month){
				unit = 'jour';
				delta /= (TIME_NOTIONS.day);
			}
			else if(delta < TIME_NOTIONS.year){
				unit = 'mois';
				delta /= (TIME_NOTIONS.month);
			}
			else{
				unit = 'année';
				delta /= (TIME_NOTIONS.year);
			}

			delta = Math.round(delta);

			if(delta > 1 && unit[unit.length-1] != 's')
				unit += 's';

			this.deltaPostedStr = delta + ' ' + unit;
		}
	}
})
